import React from 'react';
import logo from './logo-light.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Huntington-Atallah Family:<br />
          <a
            href="http://ryanatallah.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ryan Atallah
          </a>
          &nbsp;•&nbsp;
          <a
            href="https://aedwardshuntington.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ashly Huntington
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
